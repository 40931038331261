
const facebookErrorsMapper = {
  324: 'alerts.social-media.errors.facebook-post-image-missing',
  2069019: 'alerts.social-media.errors.facebook-post-image-missing',
  200: 'alerts.social-media.errors.permissions-removed',
  464: 'alerts.social-media.errors.unconfirmed-user',
  492: 'alerts.social-media.errors.invalid-session',
  458: 'alerts.social-media.errors.user-needs-to-log-in',
  459: 'alerts.social-media.errors.user-checkpointed',
  460: 'alerts.social-media.errors.password-changed',
  506: 'alerts.social-media.errors.duplicate-post',
  4: 'alerts.social-media.errors.too-many-calls',
  17: 'alerts.social-media.errors.too-many-calls',
  1: 'alerts.social-media.errors.facebook-downtime',
  2: 'alerts.social-media.errors.facebook-downtime',
  1609005: 'alerts.social-media.errors.error-posting-link',
  10: 'alerts.social-media.errors.permissions-denied',
  3: 'alerts.social-media.errors.permissions-denied',
  OAuthException: 'alerts.social-media.errors.access-token-expired',
  190: 'alerts.social-media.errors.access-token-expired',
  102: 'alerts.social-media.errors.access-token-expired',
  463: 'alerts.social-media.errors.access-token-expired',
  467: 'alerts.social-media.errors.access-token-expired',
  368: 'alerts.social-media.errors.temporary-blocked'
}

const getFacebookErrorMessage = ({ code, error_subcode: subCode }) => {
  if (!code && !subCode) return 'alerts.social-media.something-went-wrong'
  // Subcode is more detailed error than code, that's why we check it first
  return facebookErrorsMapper[subCode]
    ? facebookErrorsMapper[subCode]
    : facebookErrorsMapper[code]
}

const hasTokenExpiredCode = ({ code, error_subcode: subCode }) => {
  if (!code && !subCode) return false
  const tokenExpiredCodes = [10, 3, 190, 102, 463, 467, 200, 464, 492, 459, 460, 'OAuthException']
  return tokenExpiredCodes.includes(subCode) || tokenExpiredCodes.includes(code)
}

export const mapGraphQLErrors = (errors) => {
  return errors.map((errorItem) => {
    const error = errorItem.extensions?.exception?.error
    return {
      message: getFacebookErrorMessage(error) ?? 'alerts.social-media.something-went-wrong',
      tokenExpired: hasTokenExpiredCode(error)
    }
  })
}
