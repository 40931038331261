<template>
  <v-card
    style="overflow-x: hidden"
    class="mx-auto"
    max-width="600"
  >
    <v-card-title class="px-0 py-0">
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <div class="d-flex justify-space-between toolbar">
          <v-toolbar-title class="my-auto">
            {{ $t('social-media.link-accounts') }}
          </v-toolbar-title>
          <v-btn
            icon
            dark
            @click="closeDialog"
          >
            <v-icon large>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-spacer />
      </v-toolbar>
    </v-card-title>
    <v-card-text class="px-0 py-0">
      <p class="font-weight-bold pa-5 mb-3">
        {{ $t('social-media.connect-instagram.info') }}
      </p>
      <v-card-text class="card pa-0">
        <v-row dense>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-card
              class="pointer card-item mb-2"
              outlined
              @click="connectToInstagram()"
            >
              <v-list-item>
                <v-list-item-avatar
                  tile
                  size="30"
                  class="my-2"
                >
                  <v-img :src="KindTypeIcon.instagramPost" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-bold">
                    {{ $t('buttons.social-media.connect-instagram') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between mt-5 pt-0">
      <v-btn
        elevation="0"
        text
        :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 12 + 'px' : ''}"
        @click="closeDialog"
      >
        {{ $t('buttons.social-media.close') }}
      </v-btn>
      <v-btn
        v-if="hasInstagramConnection"
        text
        elevation="0"
        color="primary"
        :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 12 + 'px' : ''}"
        @click="openConnectSocialMediaPage()"
      >
        {{ $t('buttons.social-media.already-linked-accounts') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { KindTypeIcon, KindName } from '@/modules/contentCreator/creator/enums/KindType'
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  props: {
    hasInstagramConnection: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    KindName () {
      return KindName
    },
    KindTypeIcon () {
      return KindTypeIcon
    }
  },
  methods: {
    connectToInstagram () {
      this.$emit('connectToInstagram')
    },
    closeDialog () {
      this.$emit('close')
    },
    openConnectSocialMediaPage () {
      this.$emit('openConnectSocialMediaPage')
    }
  }
}

</script>
<style scoped>
.toolbar {
  width: 100%;
}
.line-separator {
  border-top: 2px solid #000;
  width: 100%;
  height: 1px;
  margin-top: auto;
}
</style>
